import "@shopify/polaris/build/esm/styles.css";
// import "@shopify/polaris/dist/styles.css";
import "toastr/build/toastr.css";
import "../assets/css/custom.scss";
import "../components/Settings/Settings.global.scss";
import "../components/Productreviews/productreview.global.scss";

import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider, Button } from "@shopify/polaris";
// import "react-tabs/style/react-tabs.css";
import "rc-drawer/assets/index.css";
import "draft-js-emoji-plugin/lib/plugin.css";
import toast, { Toaster } from "react-hot-toast";
import mixpanel from "mixpanel-browser";
import api, { getReviewStatusCount } from "../api";
import Head from "next/head";
import { useEffect } from "react";
import { GlobalProvider } from "context/GlobalContext";
import { ErrorBoundary } from "@sentry/react";

const theme = {
  // colors: {
  //     // topBar: {
  //     //     background: '#1b2b34',
  //     // },
  // },
  colors: {
    surface: "#1b2b34",
    onSurface: "#1b2b34",
  },
  // logo: {
  //   width: 150,
  //   topBarSource: "https://storage.googleapis.com/reviewbit/Reviewbit_Logo.png",
  //   url: "/",
  //   accessibilityLabel: "Reviewbit",
  // },
};

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    mixpanel.init("5b12b33b26f39feea2c0e6ecc16c0ef6", {
      debug: true,
      ignore_dnt: true,
    });
    mixpanel.track("dashboard");
  }, []);
  return (
    <ErrorBoundary fallback={<div className="errorBoundary">Something went wrong,  We are working to resolve this issue</div>}>
    <AppProvider i18n={enTranslations} theme={theme}>
      <Head>
        {/* translator */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500&display=swap"
          rel="stylesheet"
        ></link>
        <script
          type="text/javascript"
          src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        ></script>
        {/* Freshchat */}
        {/* <script>{`
            function initFreshChat() {
            window.fcWidget.init({
            token: "729bd8a4-39b0-4710-ae6e-5771c621c5b8",
            host: "https://wchat.in.freshchat.com"
            });
            }
            function initialize(i,t){var e;i.getElementById(t)?initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,e.src="https://wchat.in.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}function initiateCall(){initialize(document,"freshchat-js-sdk")}window.addEventListener?window.addEventListener("load",initiateCall,!1):window.attachEvent("load",initiateCall,!1);
            `}</script> */}
      </Head>
      <GlobalProvider>
        <Toaster position="bottom-center" />
        <Component {...pageProps} />
      </GlobalProvider>
    </AppProvider>
    </ErrorBoundary>
  );
}
// MyApp.getInitialProps = async (ctx) => {
//     console.dir(ctx)
//     return {}
//   }

export default MyApp;

import { BASE_URL } from "utils/Base";
import axios from "axios";
import Router from "next/router";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const merchantToken = cookies.get("merchantToken");
export const shopId = cookies.get("shopId");

// console.log(merchantToken, "mmmm");
export const api = axios.create({
  baseURL: BASE_URL,
  // params: {
  //   merchantToken: merchantToken,
  //   shopId: shopId,
  // },
  validateStatus: function (status) {
    console.log(status, "STATUS");
    if (status == 200) {
      return true;
    } else if (status == 404) {
      return Router.push("/404");
    } else if (status == 500) {
      return Router.push("/500"); // Resolve only if the status code is less than 500
    } else if (status == 401) {
      return Router.push("/Login"); // Resolve only if the status code is less than 500
    }
  },
});

api.interceptors.response.use(
  (response) => {
    console.log(response.status, "AXIOS SUCCESS INTERCEPTOR");

    return response;
  },
  (error) => {
    console.log(error, "AXIOS ERROR INTERCEPTOR");
    return Promise.reject(error);
    // Resolve only if the status code is less than 500
  }
);

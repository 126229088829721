import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Router from "next/router";
export const GlobalContext = createContext();
import { BASE_URL } from "utils/Base";

export const GlobalProvider = ({ children }) => {
  const [isauth, setAuth] = useState(false);
  const [merchant_token, setToken] = useState(null);
  const [shop_token, setShopToken] = useState(null);

  const cookies = new Cookies();

  useEffect(async () => {
    const auth = await cookies.get("merchantToken");
    const shop = await cookies.get("shopId");
    if (auth && shop) {
      setToken(auth);
      setShopToken(shop);
      setAuth(true);
      console.log("token validation -->", merchant_token);
    }
  }, [merchant_token == null]);

  const api = axios.create({
    baseURL: BASE_URL,
    params: {
      merchantToken: merchant_token,
      shopId: shop_token,
    },
    validateStatus: function (status) {
      console.log(status, "STATUS");
      if (status == 200) {
        return true;
      } else if (status == 404) {
        return Router.push("/404");
      } else if (status == 500) {
        return Router.push("/500"); // Resolve only if the status code is less than 500
      } else if (status == 401) {
        return Router.push("/Login"); // Resolve only if the status code is less than 500
      }
    },
  });

  return (
    <GlobalContext.Provider
      value={{
        isauth,
        merchant_token,
        shop_token,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

import { AppProvider } from "@shopify/polaris";
import axios from "axios";
import Cookies from "universal-cookie";
import { BASE_URL } from "utils/Base";
import Router from "next/router";
import { api } from "./base";

// export const api = axios.create({
//   baseURL: BASE_URL,
//   //baseURL: 'http://localhost/backend/api',
// });
// console.log(BASE_URL, "yy");
const cookies = new Cookies();

const merchantToken = cookies.get("merchantToken");
const shopId = cookies.get("shopId");

export const getDatalist = (
  dateFilter = null,
  startDate = null,
  endDate = null
) =>
  api.get(`/dashboarddata`, {
    merchantToken: merchantToken,
    dateFilter: dateFilter,
    startDate: startDate,
    endDate: endDate,
  });
export const getReviewStatusCount = (
  merchantToken = merchantToken,
  shopId = shopId
) =>
  api.get(`/reviewStatusCount`, {
    params: {
      merchantToken,
      shopId,
    },
  });

// export const getProductreviews = (merchantToken, shopId) =>
// api.post(`/productList`, { merchantToken: merchantToken, shopId: shopId });
// productDetails
// export const getProductdetails = (merchantToken,shopId,productId) =>

// api.post(`/productDetails`, {merchantToken: merchantToken, shopId: shopId,productId:productId });

export const AuthVerify = (merchantToken = merchantToken, shopId = shopId) =>
  api.get(`/reviewStatusCount`, {
    params: {
      merchantToken,
      shopId,
    },
  });
export const onboardingflowstage = (
  merchantToken,
  shopId,
  onboardingflowstage
) =>
  api.patch(`/onboardingflowstage`, {
    merchantToken,
    shopId,
    onboardingflowstage,
  });
export const importorders = (merchantToken, shopId, orderBackupDays) =>
  api.patch(`/importorders`, {
    merchantToken,
    shopId,
    orderBackupDays,
  });
export const updateReviewStatus = (reviewstatus, merchantToken, shopId) =>
  api.patch(`/reviewstatus`, {
    merchantToken: merchantToken,
    shopId: shopId,
    reviewstatus: reviewstatus,
  });
export const updateTimeGap = (timeGap) =>
  api.patch(`/timegap`, {
    merchantToken: merchantToken,
    shopId: shopId,
    timeGap: timeGap,
  });
export const updatefeedbackTiming = (feedbackTiming) =>
  api.patch(`/updatefeedbackTiming`, {
    merchantToken: merchantToken,
    shopId: shopId,
    feedbackTiming: feedbackTiming,
  });
export const updateitemLimit = (itemLimit) =>
  api.patch(`/updateitemLimit`, {
    merchantToken: merchantToken,
    shopId: shopId,
    itemLimit: itemLimit,
  });
export const updateMessageEvent = (messageEvent) =>
  api.patch(`/messageevent`, {
    merchantToken: merchantToken,
    shopId: shopId,
    messageEvent: messageEvent,
  });
export const updateshopName = (shopName) =>
  api.patch(`/shopname`, {
    merchantToken: merchantToken,
    shopId: shopId,
    shopName: shopName,
  });

export const switchChatflow = (status) =>
  api.patch(`chatFlowUpdate`, {
    merchantToken: merchantToken,
    shopId: shopId,
    chatFlowStatus: status,
  });

export const updatemsgLanguage = (msgLanguage) =>
  api.patch(`/msglanguage`, {
    merchantToken: merchantToken,
    shopId: shopId,
    msgLanguage: msgLanguage,
  });
export const getReviews = (merchantToken, shopId) =>
  api.post(`/getreviews`, { merchantToken: merchantToken, shopId: shopId });

export const reviewsEdit = (
  reviewid = null,
  reviewTitle = null,
  reviewText = null,
  deletedMedias = []
) =>
  api.post(`/reviewEdit`, {
    merchantToken: merchantToken,
    shopId: shopId,
    reviewId: reviewid,
    reviewTitle: reviewTitle,
    reviewText: reviewText,
    deletedMedias: deletedMedias,
  });
export const productRatingmsg = (
  messageBody = null,
  messagetitle = null,
  messageLanguage = null
) =>
  api.post(`/productratingmsg`, {
    merchantToken: merchantToken,
    shopId: shopId,
    messageBody: messageBody,
    messagetitle: messagetitle,
    messageLanguage: messageLanguage,
  });
export const productRatingmsgupdate = (
  ratingMsg = null,
  ratingMsgTitle = null
) =>
  api.patch(`/productratingmsg`, {
    merchantToken: merchantToken,
    shopId: shopId,
    ratingMsg: ratingMsg,
    ratingMsgTitle: ratingMsgTitle,
  });
export const reviewsApproval = (
  reviewid = null,
  merchantToken = merchantToken,
  shopId = shopId
) =>
  api.patch(`/reviewApproval`, {
    merchantToken: merchantToken,
    shopId: shopId,
    reviewId: reviewid,
  });
export const myRequest = (
  merchantToken = merchantToken,
  shopId = shopId,
  reviewFilter = null,
  ratingFilter = null,
  dateFilter = null,
  searchStr = null,
  startDate = null,
  endDate = null,
  showLimit = null,
  page = null,
  sortType = null,
  sortFilter = null
) =>
  api.get(`/getreviews`, {
    params: {
      merchantToken,
      shopId,
      reviewFilter,
      ratingFilter,
      dateFilter,
      searchStr,
      startDate,
      endDate,
      showLimit,
      page,
      sortType,
      sortFilter,
    },
  });
export const getProductdetails = (
  reviewFilter = null,
  ratingFilter = null,
  dateFilter = null,
  searchStr = null,
  startDate = null,
  endDate = null,
  showLimit = null,
  page = null,
  sortType = null,
  sortFilter = null,
  productId = null
) =>
  api.post(`/productDetails`, {
    // params: {
    merchantToken,
    shopId,
    reviewFilter,
    ratingFilter,
    dateFilter,
    searchStr,
    startDate,
    endDate,
    showLimit,
    page,
    sortType,
    sortFilter,
    productId,

    // },
  });
export const getProductreviews = (
  filterType = null,
  dateFilter = null,
  searchStr = null,
  startDate = null,
  endDate = null,
  showLimit = null,
  page = null,
  sortType = null,
  sortFilter = null
) =>
  api.post(`/productList`, {
    // params: {
    merchantToken,
    shopId,
    filterType,
    dateFilter,
    searchStr,
    startDate,
    endDate,
    showLimit,
    page,
    sortType,
    sortFilter,
    // },
  });
export const getReviewRequests = (
  merchantToken = merchantToken,
  shopId = shopId,
  filterType = null,
  dateFilter = null,
  searchStr = null,
  startDate = null,
  endDate = null,
  showLimit = null,
  page = null,
  sortType = null,
  sortFilter = null
) =>
  api.get(`/review_requests`, {
    params: {
      merchantToken,
      shopId,
      filterType,
      dateFilter,
      searchStr,
      startDate,
      endDate,
      showLimit,
      page,
      sortType,
      sortFilter,
    },
  });
export const deleteReviewrequest = (requestId,merchantToken = merchantToken, shopId = shopId) =>
  api.delete("/delete_requests", {
    params: {
      merchantToken,
      shopId,
      requestId,
    },
  });
export const restoreReviewrequest = (requestId,merchantToken = merchantToken, shopId = shopId) =>
  api.post("/restore_review_requests", {
    merchantToken: merchantToken,
    shopId: shopId,
    requestId: requestId,
  });
export const sendReviewRequest = (requestId,merchantToken = merchantToken, shopId = shopId) =>
  api.post("/send_requests", {
    merchantToken,
    shopId,
    requestId,
  });
export const resendReviewRequest = (requestId,merchantToken = merchantToken, shopId = shopId) =>
  api.post("/resend_requests", {
    merchantToken,
    shopId,
    requestId,
  });

export const reScheduleRequest = (requestId, sheduleDate,merchantToken = merchantToken, shopId = shopId) =>
  api.post("/reshedule_requests", {
    merchantToken,
    shopId,
    requestId,
    sheduleDate,
  });

export const getConfigData = (merchantToken = merchantToken, shopId = shopId) =>
  api.post(`/configdata`, { merchantToken: merchantToken, shopId: shopId });
// new chat flow fetch
export const getChatConfigData = () =>
  api.post(`/getShopConfig`, {
    merchantToken: merchantToken,
    shopId: shopId,
  });
// more templates
export const getMoreTemplates = () =>
  api.post(`/getMoreTemplates`, {
    merchantToken: merchantToken,
    shopId: shopId,
  });
// rating message approvel request
export const createZokoProductRatingMsg = (templateText, templateTitle) =>
  api.post(`/createZokoProductRatingMsg`, {
    merchantToken: merchantToken,
    shopId: shopId,
    templateText: templateText,
    templateTitle: templateTitle,
  });
// quick replies edit

export const editQuickReplies = (data) =>
  api.post(`/editQuickReplies`, data, {
    params: {
      merchantToken: merchantToken,
      shopId: shopId,
    },
  });

// normal template edit
export const editZokoTextMessages = (templateTitle, templateText) =>
  api.patch(`/editZokoTextMessages`, {
    // params: {
    merchantToken: merchantToken,
    shopId: shopId,
    templateTitle: templateTitle,
    templateText: templateText,
    // },
  });

// switch language
export const switchMessageLanguage = (messageLanguage) =>
  api.patch(`/chatlanguageConfig`, {
    // params: {
    shopId: shopId,
    merchantToken: merchantToken,
    msgLanguage: messageLanguage,
    // },
  });

// active default template
export const activeDefaultTemplate = (templateId) =>
  api.patch(`/updateDefaultTemplate`, {
    // params: {
    shopId: shopId,
    merchantToken: merchantToken,
    tempId: templateId,
    // },
  });

// enable discount
export const enableMessageDiscount = (discountEnabled) =>
  api.patch(`/discountEnabling`, {
    // params: {
    shopId: shopId,
    merchantToken: merchantToken,
    discountEnabled: discountEnabled,
    // },
  });

export const reviewmessage = (reviewMsg, discountEnabled) =>
  api.patch(`/reviewmessage`, {
    merchantToken: merchantToken,
    shopId: shopId,
    reviewMsg,
    discountEnabled,
  });
export const photomessage = (photoMsg, discountEnabled) =>
  api.patch(`/photomessage`, {
    merchantToken: merchantToken,
    shopId: shopId,
    photoMsg,
    discountEnabled,
  });
export const thankyoumessage = (thankyouMsg) =>
  api.patch(`/thankyoumessage`, {
    merchantToken: merchantToken,
    shopId: shopId,
    thankyouMsg,
  });
export const lowrattingsupport = (
  supportMsg,
  supportStatus,
  countryCode,
  PhoneNumber
) =>
  api.patch(`/lowrattingsupport`, {
    merchantToken: merchantToken,
    shopId: shopId,
    supportMsg,
    supportStatus,
    countryCode,
    PhoneNumber,
  });
export const creatediscount = (discountType, discountValue, couponCode) =>
  api.post(`/creatediscount`, {
    merchantToken,
    shopId,
    discountType,
    discountValue,
    couponCode,
  });

export const reviewExport = (
  merchantToken  = merchantToken,
  shopId  = shopId,
  exportFilter = null,
  app_name = null,
  reviewFilter = null,
  showLimit = null,
  page = null,
  searchStr = null,
  dateFilter = null,
  startDate = null,
  endDate = null,
  selectedReviews = null,
  ratingFilter = null
) =>
  api.post(`/reviewExport`, {
    merchantToken,
    shopId,
    exportFilter,
    app_name,
    reviewFilter,
    showLimit,
    page,
    searchStr,
    dateFilter,
    startDate,
    endDate,
    selectedReviews,
    ratingFilter,
  });

// export const reviewImport=({importDatafile,providerData})=>
// {
// const formData = new FormData();
// for( var i = 0; i < importDatafile.length; i++ ){
//   let file =importDatafile[i];
//   formData.append('files[' + i + ']', file);
// }
// formData.append("reviewprovider",providerData);
// const options={data:formData}
// api.post(`/importReviews`, data=formData,
// //  {
// //   merchantToken: merchantToken,
// //   shopId: shopId,

// // }
// )
// }
export const checkPremium = () =>
  api.get(`/premiumChecking`, {
    params: {
      shopId,
    },
  });

export const upgradetoPremium = (subscriptionStatus = true) =>
  api.post(`/upgradeToPremium`, {
    shopId,
    subscriptionStatus,
  });

export const integratedAppsList = () =>
  api.post(`/integratedAppsList`, {
    merchantToken,
    shopId,
  });

export const enableReviewAppIntegration = (
  appName = null,
  status = null,
  token,
  storeHash
  // stampedValue=null,
  // storeValue=null
) =>
  api.post(`enableReviewAppIntegration`, {
    merchantToken,
    shopId,
    appName,
    status,
    token,
    storeHash,
    // stampedValue,
    // storeValue
  });
export const integratedAppDetails = (appName = null) =>
  api.post(`integratedAppDetails`, {
    merchantToken,
    shopId,
    appName,
  });

export const widgetConfig = () =>
  api.post(`/widgetConfig`, {
    shopId,
  });

export const updateWidget = (
  reviewWidgetStatus,
  sortingOrder,
  reviewsLimit,
  reviewTitleColor,
  reviewRatingColor,
  reviewButtonColor,
  reviewButtonStyleRadius,
  selectedButton,
  brandingVisibility
) =>
  api.post(`/updateWidget`, {
    shopId,
    reviewWidgetStatus,
    sortingOrder,
    reviewsLimit,
    reviewTitleColor,
    reviewRatingColor,
    reviewButtonColor,
    reviewButtonStyleRadius,
    selectedButton,
    brandingVisibility,
  });

export const dashboarddata = (merchantToken) =>
  api.get(`/dashboarddata`, {
    params: {
      merchantToken,
      dateFilter,
      startDate,
      endDate,
    },
  });

export const reviewsBulkApproval = (
  reviewid = "",
  merchantToken = merchantToken,
  shopId = shopId
) =>
  api.patch(`/BulkreviewApproval`, {
    merchantToken: merchantToken,
    shopId: shopId,
    SelectedReviewId: reviewid,
  });

export const reviewsBulkReject = (
  reviewid = "",
  merchantToken = merchantToken,
  shopId = shopId
) =>
  api.patch(`/ReviewRejection`, {
    merchantToken: merchantToken,
    shopId: shopId,
    SelectedReviewId: reviewid,
  });

export const reviewsBulkDelete = (
  reviewid = "",
  merchantToken = merchantToken,
  shopId = shopId
) =>
  api.patch(`/ReviewDeletion`, {
    merchantToken: merchantToken,
    shopId: shopId,
    SelectedReviewId: reviewid,
  });

export const BulkReviewRequestSend = (
  reviewid = "",
  merchantToken = merchantToken,
  shopId = shopId
) =>
  api.patch(`/BulkReviewRequestSent`, {
    merchantToken: merchantToken,
    shopId: shopId,
    SelectedReviewId: reviewid,
  });

// export const NewWhatsapptempate=(merchantToken, shopId) => api.post(`/getShopConfig`,{
//     'merchantToken': merchantToken,
//      'shopId': shopId

// })
export const publishedReviewsaction = ({
  reviewid,
  publishhideStatus,
  merchantToken: merchantToken,
  shopId: shopId,
}) =>
  api.patch(`/updatePublishedStatus`, {
    merchantToken: merchantToken,
    shopId: shopId,
    SelectedReviewId: reviewid,
    publishedStatus: publishhideStatus,
  });

export const moderationsettings = (
  merchantToken = merchantToken,
  shopId = shopId
) =>
  api.post(`/getModerationSettings`, {
    merchantToken: merchantToken,
    shopId: shopId,
  });
export const updatemoderationsettings = ({
  autoApprovalStatus,
  minimumRating,
  autoMediaApproval,
  autopublishStatus,
}) =>
  api.patch(`/updateModerationSettings`, {
    merchantToken: merchantToken,
    shopId: shopId,
    autoApprovalStatus: autoApprovalStatus,
    minimumRating: minimumRating,
    autoMediaApproval: autoMediaApproval,
    autoPublishStatus: autopublishStatus,
  });

  export const getHiddenReviews = (
    merchantToken = merchantToken,
      shopId = shopId,
  ) =>
    api.get(`/get-blocked-reviews`, {
      params:{

        merchantToken: merchantToken,
        shopId: shopId,
      }
    });

const apis = {
  getHiddenReviews,
  getDatalist,
  updateReviewStatus,
  updateTimeGap,
  updateMessageEvent,
  updateshopName,
  updatemsgLanguage,
  lowrattingsupport,
  getConfigData,
  reviewmessage,
  photomessage,
  thankyoumessage,
  getReviews,
  getReviewRequests,
  deleteReviewrequest,
  restoreReviewrequest,
  getReviewStatusCount,
  reviewsApproval,
  onboardingflowstage,
  importorders,
  upgradetoPremium,
  checkPremium,
  widgetConfig,
  updateWidget,
  updatefeedbackTiming,
  AuthVerify,
  updateitemLimit,
  integratedAppsList,
  enableReviewAppIntegration,
  integratedAppDetails,
  getChatConfigData,
  createZokoProductRatingMsg,
  editQuickReplies,
  editZokoTextMessages,
  switchMessageLanguage,
  activeDefaultTemplate,
  enableMessageDiscount,
  getMoreTemplates,
  creatediscount,
  moderationsettings,
  updatemoderationsettings,
  publishedReviewsaction,
  // reviewImport,
  myRequest,
  getProductreviews,
  getProductdetails,
  switchChatflow,
};

export default apis;
